<template>
  <div class="">
    <ChatForm ref="form" action="Update" v-model="formData" @formSubmitted="updateChat" />
  </div>
</template>
  
  <script>
import ChatForm from "@/views/components/chat/ChatForm.vue";
import ChatService from "@/services/ChatService";

export default {
  name: "UpdateChat",
  components: {
    ChatForm
  },
  data() {
    return {
      chatService: new ChatService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateChat(e) {
      this.$refs.form.loading = true;
      this.chatService.update(this.formData._id, e).then(res => {
        this.$router.push('/chats')
      }).catch(err => {
        console.log(err);
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getChat() {
      this.chatService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
        this.formData.topics = this.formData.topics.map(e => e._id);
        this.formData.languages = this.formData.languages.map(e => e._id);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getChat()
  }
}
</script>
  
  <style scoped>
  </style>
  